import React from 'react';

const NewCardSmall = () => {
  return (
  <div>
      <h3>New Card</h3>
      <div className="card-union"></div>
  </div>
    )
};

export default NewCardSmall;
